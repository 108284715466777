import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Theme } from "@mui/system/createTheme";
import { Link } from "react-router-dom";
import { Maillink, Phonelink } from "../../utils/Linkings";

function Footer() {
  const useStyles = makeStyles((theme: Theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: "#f7faff",
      paddingTop: 50,
      paddingBottom: 50,
      paddingInline: 30,
      gap: 25,
    },
    footer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      gap: 5,
      "&:hover": {},
      "& div": {
        display: "flex",
        flexDirection: "column",
        gap: 25,
      },
      "& div span": {
        color: "#f5f5f5",
        fontSize: 14,
        fontWeight: 600,
      },
      "& div div": {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        gap: 14,
        color: "#f5f5f5",
      },
      "& div div a": {
        fontSize: 12,
        display: "flex",
        flexDirection: "column",
        color: "#f5f5f5",
        "&:hover": {
          textDecoration: "underline",
        },
      },
      [theme.breakpoints.down("md")]: {
        display: "flex",
        flexDirection: "column",
        gap: 20,
      },
    },
    bottom: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: 12,
      marginTop: 20,
      color: "#f5f5f5",
    },
    contact: {
      textDecoration: "underline",
      fontWeight: 500,
    },
  }));

  const classes = useStyles();

  return (
    <div className={`${classes.root} px-6 md:px-[120px] bg-primary`}>
      <Box className={classes.footer}>
        {" "}
        <div>
          <span>STS</span>
          <Box sx={{ textAlign: "justify", width: { md: 500 } }}>
            STS (Sénégal Transport Solution) est une start-up ✋ Sénegalaise de
            mobilité privée. <br />À la recherche d'une voiture de location à
            Dakar ? Ne cherchez plus ! STS est là pour répondre à tous vos
            besoins en matière de location de voitures. Louez votre voiture
            idéale à Dakar (et dans tout le Sénégal, avec ou sans chauffeur
            privé.) avec STS !.
          </Box>
        </div>
        
        <div>
          <span>NOS SERVICES</span>
          <div>
            <Link to="/">Vente de billets d’avions</Link>
            <Link to="/">Réservation hotels</Link>
            <Link to="/">Assurance voyage</Link>
            <Link to="/">Location de voitures</Link>
            <Link to="/">Tourisme</Link>
          </div>
        </div>

        <div>
          <span>AUTRES</span>
          <div>
            <Link to="/terms-of-service" target="_blank">
              Conditions d'utilisation
            </Link>
            <Link to="/privacy-policy" target="_blank">
              Politique de confidentialité
            </Link>
          </div>
        </div>
        
        <div>
          <span>NOUS CONTACTER</span>
          <div>
            <Maillink email="sts@senegaltransportsolutions.com">
              <div className={classes.contact}>
                sts@senegaltransportsolutions.com
              </div>
            </Maillink>
            <div className={classes.contact}>
              Cité Magistrat derrière Keur Yoff
            </div>
            <div className="flex flex-row">
              <Phonelink phone="+221775372893">
                <div className={classes.contact}>(+221)775 372 893</div>
              </Phonelink>
              <span>/</span>
              <Phonelink phone="+221774462370">
                <div className={classes.contact}>(+221) 774 462 370</div>
              </Phonelink>
            </div>
          </div>
        </div>
      </Box>

      <Box>
        <div>
          <Box sx={{ height: "1px", backgroundColor: "white", opacity: 0.7 }} />
          <div className={classes.bottom}>
            <div>Réalisé par Gesta avec 💗</div>
            <span>© STS {new Date().getFullYear()}</span>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default Footer;
