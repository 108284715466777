// @ts-ignore 
// @ts-nocheck
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Toast } from "primereact/toast";
import { classNames } from 'primereact/utils';
import { useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useQuery } from 'react-query';
import Swal from "sweetalert2";
import { appConfig } from '../../../contants/configurations';
import Dashboardlayout from '../../../layouts/Dashboardlayout';
import axiosInstance from '../../../utils/axiosInstance';

function Contractadd() {
    const voiture = useQuery('fetchVoiture', async () => {
        const { data } = await axiosInstance.get(`${appConfig.API_URL}/voiture`);
        return data.data;
    })

    const automobile = [
        {
            name: 'Berlin'
        },
        {
            name: 'Bus'
        },
        {
            name: '4x4'
        },
        {
            name: 'Pick up'
        },
        {
            name: 'Mini bus'
        },
    ]

    let emptyContract = {
        nom: null,
        prenom: null,
        voiture: null,
        auto: 0,
        nbr_cv: 0,
        tel: '',
        lieunaiss: '',
        datenaiss: '',
        adresse: '',
        depart: '',
        retour: '',
        date: '',
        numpermis: '',
        destination: '',
        caution: 0,
        prix_jour: 0,
        prix_mois: 0,
    }

    const { control, formState: { errors }, handleSubmit, getValues, reset } = useForm({ emptyContract })

    const [formValues, setFormValues] = useState(emptyContract)

    const toast = useRef<Toast>(null)

    const saveContract = async (data) => {
        if (data.depart>data.retour) {
            return Swal.fire(
                        "Veuilez Verifier vos dates","",
                        'info')
        }
        
        await axiosInstance.post(`${appConfig.API_URL}/contract`, data).then((response) => {
            toast.current?.show({ severity: 'success', summary: 'Enregistré', detail: response.data.message, life: 8000 });
             setFormValues(emptyContract);
             reset()
        }).catch((error) => {
            Swal.fire(
                'Sorry!',
                error.response.data.message,
                'error')
        })
    }

    const getFormErrorMessage = (name) => {
        return errors[name] ? <small className="p-error">{errors[name].message}</small> : <small className="p-error">&nbsp;</small>;
    }

    return (
        <Dashboardlayout title="Nouveau Contrat">
            <div className='h-full'>
                <Toast ref={toast} />
                {/* /* ################ ==================== ############################ */}
                <form onSubmit={handleSubmit(saveContract)} className='flex flex-col p-4 gap-2 border-2 border-green-700 rounded-sm bg-white' encType="multipart/form-data">

                    {/* Infos Car */}
                    <div>
                        <span className=' text-2xl font-semibold'>Informations de Véhicules</span>
                    </div>
                    {/* Vehicules & Auto */}
                    <div className='flex flex-col md:flex-row md:gap-2'>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="voiture"
                                control={control}
                                rules={{ required: 'Véhicule is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={'font-medium' + classNames({ 'p-error': errors.value })}>Véhicule *</label>
                                        <Dropdown
                                            id={field.name}
                                            value={field.value}
                                            optionLabel="immatriculation"
                                            optionValue='_id'
                                            placeholder="Selectionner"
                                            focusInputRef={field.ref}
                                            onChange={(e) => field.onChange(e.value)}
                                            className={'w-full md:w-14rem"' + classNames({ 'p-invalid': fieldState.error })}
                                            options={voiture.data}
                                        />
                                        {getFormErrorMessage('voiture')}
                                    </>
                                )}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="auto"
                                control={control}
                                rules={{ required: 'Automobile is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={'font-medium' + classNames({ 'p-error': errors.value })}>Automobile *</label>
                                        <Dropdown
                                            id={field.name}
                                            value={field.value}
                                            optionLabel="name"
                                            optionValue='name'
                                            placeholder="Selectionner"
                                            focusInputRef={field.ref}
                                            onChange={(e) => field.onChange(e.value)}
                                            className={'w-full md:w-14rem"' + classNames({ 'p-invalid': fieldState.error })}
                                            options={automobile}
                                        />
                                        {getFormErrorMessage('auto')}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    <div>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="nbr_cv"
                                control={control}
                                rules={{ required: 'Nombre de CV is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Nombres de CV *</label>
                                        <InputText id={field.name} type='number' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* Infos Locations */}
                    <div>
                        <span className=' text-2xl font-semibold'>Informations du Client</span>
                    </div>

                    {/* Nom & Prenom */}
                    <div className='flex flex-col md:flex-row md:gap-2'>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="nom"
                                control={control}
                                rules={{ required: 'Nom is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Nom *</label>
                                        <InputText id={field.name} type='text' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="prenom"
                                control={control}
                                rules={{ required: 'Prénom is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Prénom *</label>
                                        <InputText id={field.name} type='text' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* Lieu de Naissance & Date Naissance */}
                    <div className='flex flex-col md:flex-row md:gap-2'>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="lieunaiss"
                                control={control}
                                rules={{ required: 'Lieu de Naissance is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Lieu de Naissance *</label>
                                        <InputText id={field.name} type='text' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="datenaiss"
                                control={control}
                                rules={{ required: 'Date de Naissance is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Date de Naissance  *</label>
                                        <Calendar inputId={field.name} value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)} dateFormat="dd/mm/yy" showIcon />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* Tel & Permis */}
                    <div className='flex flex-col md:flex-row md:gap-2'>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="tel"
                                control={control}
                                rules={{ required: 'Téléphone is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Téléphone *</label>
                                        <InputText id={field.name} type='text' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="numpermis"
                                control={control}
                                rules={{ required: 'Permis is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>N° Permis *</label>
                                        <InputText id={field.name} type='text' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* Adresse */}
                    <div>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="adresse"
                                control={control}
                                rules={{ required: 'Adresse is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Adresse *</label>
                                        <InputText id={field.name} type='text' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* Infos Locations */}
                    <div>
                        <span className=' text-2xl font-semibold'>Informations de la Location</span>
                    </div>

                    {/* Départ & Retour */}
                    <div className='flex flex-col md:flex-row md:gap-2'>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="depart"
                                control={control}
                                rules={{ required: 'Départ is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={'font-medium'}>Date de Départ *</label>
                                        <InputText id={field.name} type='datetime-local'  value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>

                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="retour"
                                control={control}
                                rules={{ required: 'Retour is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Date de Retour  *</label>
                                        <InputText id={field.name} type='datetime-local' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* Destination & Caution */}
                    <div className='flex flex-col md:flex-row md:gap-2'>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="destination"
                                control={control}
                                rules={{ required: 'Destination is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Destination *</label>
                                        <InputText id={field.name} type='text' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="caution"
                                control={control}
                                rules={{ required: 'Caution is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Caution Versé *</label>
                                        <InputNumber inputId={field.name} mode='currency' currency='XAF' locale="en-US" value={field.value} showButtons
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.value)}
                                        // onValueChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* Prix/jour & Prix/mois */}
                    <div className='flex flex-col md:flex-row md:gap-2'>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="prix_jour"
                                control={control}
                                rules={{ required: 'Prix is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Prix/jour *</label>
                                        <InputNumber inputId={field.name} mode='currency' currency='XAF' locale="en-US" value={field.value} showButtons
                                            inputClassName={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            // onValueChange={(e) => field.onChange(e.value)}
                                            onChange={(e) => field.onChange(e.value)}
                                        />{getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="prix_mois"
                                control={control}
                                rules={{ required: 'Prix is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Prix/mois *</label>
                                        <InputNumber inputId={field.name} mode='currency' currency='XAF' locale="en-US" value={field.value} showButtons
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.value)}
                                        // onValueChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>
                    </div>

                    {/* Départ & Retour */}
                    <div className='flex flex-col md:flex-row md:gap-2'>
                        <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="date"
                                control={control}
                                rules={{ required: 'Date is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className={'font-medium'}>Signé le *</label>
                                        <InputText id={field.name} type='datetime-local' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div>

                        {/* <div className="w-full flex flex-col gap-2">
                            <Controller
                                name="retour"
                                control={control}
                                rules={{ required: 'Retour is required.' }}
                                render={({ field, fieldState }) => (
                                    <>
                                        <label htmlFor={field.name} className='font-medium'>Date de Retour  *</label>
                                        <InputText id={field.name} type='datetime-local' value={field.value}
                                            className={'w-full' + classNames({ 'p-invalid': fieldState.error })}
                                            onChange={(e) => field.onChange(e.target.value)}
                                        />
                                        {getFormErrorMessage(field.name)}
                                    </>
                                )}
                            />
                        </div> */}
                    </div>








                    <div className='w-full flex gap-4 p-2 border-[1px] rounded-md mt-4'>
                        <Button label='Réinitialiser' icon="pi pi-times" className='w-full' severity="secondary" type='reset' raised />
                        <Button label='Enregistrer' icon="pi pi-check" className='w-full' severity="info" type='submit' raised />
                    </div>
                </form>
            </div>
        </Dashboardlayout>
    )
}

export default Contractadd